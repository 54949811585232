import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import { confirmDialog } from 'primereact/confirmdialog';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useMsal } from '@azure/msal-react';
import { useDispatch, useSelector } from '../../redux/create-store';
import { setAssessment } from '../../app-slice';
import { WithAssessmentV3, assessmentV3Connector } from '../../redux/slices/assessment-v3-slice';
import generalValidation from '../../validation/createValidation';
import './assessment.scss';

type CreateAssessmentProps = WithAssessmentV3;

export const EditAssessment: React.FC<CreateAssessmentProps> = (props) => {
  const { getAssessment, editAssessment } = props;
  const { instance } = useMsal();
  const navigate = useNavigate();
  const { assessmentId }= useParams<{ assessmentId: string }>();

  const viewAssessmentUrl = '/';
  const dispatch = useDispatch();
  
  const initialValuesTest = useSelector((root) => root.assessmentV3.byId[assessmentId ?? '']);

  useEffect(() => {
    dispatch(setAssessment({ id: assessmentId }));
    if (assessmentId) {
      getAssessment({ assessmentId, instance }); 
    }
  }, [assessmentId, dispatch, getAssessment, instance]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name:  initialValuesTest?.name,
      description: initialValuesTest?.description,
      client: initialValuesTest?.client,
      sponsor: initialValuesTest?.sponsor,
      startDate: initialValuesTest?.startDate ? new Date(initialValuesTest.startDate * 1000) : new Date(),
      endDate: initialValuesTest?.endDate ? new Date(initialValuesTest.endDate * 1000) : new Date(),
      finalizationDate: initialValuesTest?.finalizationDate ? new Date(initialValuesTest.finalizationDate * 1000) : new Date(),
    },   
    validationSchema: generalValidation,       
    onSubmit: (data) => {
      console.log('data=', data);
      console.log('startDate=', data.startDate?.getTime());

      const assessmentData = {
        name: data.name,
        description: data.description,
        client: data.client,
        sponsor: data.sponsor,
        startDate: data.startDate.toISOString(),
        endDate: data.endDate.toISOString(),
        finalizationDate: data.finalizationDate.toISOString(),
      };

      editAssessment({data: assessmentData, instance, assessmentId: assessmentId ?? ''});

      navigate(viewAssessmentUrl);
    }
  });


  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;

  const isFormFieldValid = (name: string) => !!(formikTouched[name] && formikErrors[name]);
  const getFormErrorMessage = (name: string) => {
    return isFormFieldValid(name) && <small className='p-error'>{formikErrors[name]}</small>;
  };
  
  const toast = useRef(null);

  const accept = () => {
    navigate(viewAssessmentUrl);
  };

  const reject = () => {
    toast.current
  };
    
  const confirmCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    confirmDialog({
      header: 'Confirmation',
      message: 'All your updates would not be saved. Are you sure you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept,
      reject
    });
  };

  if (!initialValuesTest) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <h2 className='mat-assessment-create__title'>
            Edit new Assessment
      </h2>
      {/* <Toast ref={toast} /> */}
      <div className='mat-assessment-create__card'>
        <Card>
          <form className='mat-assessment-create__form' onSubmit={formik.handleSubmit}>
            
            <h4> Assessment Details </h4>
            <div>
              <label htmlFor="startDate" className="mat-assessment-create__inputLabel">Start*</label>
              <Calendar id="startDate" 
                className={classNames({ 'p-invalid': isFormFieldValid('startDate') })} 
                // className={classNames('mat-assessment-create__inputField')}
                value={formik.values.startDate} 
                onChange={formik.handleChange} dateFormat="yy-mm-dd" name="startDate" showIcon/>
              {getFormErrorMessage('startDate')}
            </div>
            <div>
              <label htmlFor="endDate" className="mat-assessment-create__inputLabel">End*</label>
              <Calendar id="endDate" 
                className={classNames({'p-invalid': isFormFieldValid('endDate')})} 
                // className={classNames('mat-assessment-create__inputField')}
                value={formik.values.endDate}
                onChange={formik.handleChange} dateFormat="yy-mm-dd" name="endDate" showIcon/>
              {getFormErrorMessage('endDate')}
            </div>
            <div>
              <label htmlFor="finalizationDate" className="mat-assessment-create__inputLabel">Finalization*</label>
              <Calendar id="finalizationDate" 
                className={classNames({'p-invalid': isFormFieldValid('finalizationDate')})} 
                // className={classNames('mat-assessment-create__inputField')}
                value={formik.values.finalizationDate}
                onChange={formik.handleChange} dateFormat="yy-mm-dd" name="finalizationDate" showIcon/>
              {getFormErrorMessage('finalizationDate')}
            </div> 
            <div>
              <label htmlFor="name" className="mat-assessment-create__inputLabel">Name*</label>
              <InputText id="name" 
                className={classNames({ 'p-invalid': isFormFieldValid('name') }, 'mat-assessment-create__inputField')} 
                // className={classNames('mat-assessment-create__inputField')} 
                value={formik.values.name} onChange={formik.handleChange}
                autoFocus name="name"/>
              {getFormErrorMessage('name')}
            </div>
            <div>
              <label htmlFor="description" className="mat-assessment-create__inputLabel">Description*</label>
              <InputText id="description" 
                className={classNames({ 'p-invalid': isFormFieldValid('description') }, 'mat-assessment-create__inputField')} 
                value={formik.values.description} onChange={formik.handleChange}
                autoFocus name="description"/>
              {getFormErrorMessage('description')}
            </div>

            <h4> Client Details </h4>
            <div>
              <label htmlFor="client" className="mat-assessment-create__inputLabel">Client Name</label>
              <InputText id="client"  
                className={classNames({ 'p-invalid': isFormFieldValid('client') }, 'mat-assessment-create__inputField')} 
                value={formik.values.client} onChange={formik.handleChange}
                name="client" />
              {getFormErrorMessage('client')}
            </div>
            <div>
              <label htmlFor="sponsor" className="mat-assessment-create__inputLabel">Sponsor Name</label>
              <InputText id="sponsor"  
                className={classNames({ 'p-invalid': isFormFieldValid('sponsor') }, 'mat-assessment-create__inputField')} 
                value={formik.values.sponsor} onChange={formik.handleChange}
                name="sponsor" />
              {getFormErrorMessage('sponsor')}
            </div> 
            <div className='mat-assessment-create__actions'>
              <Button label='Cancel' className='p-button-link' onClick={confirmCancel}/>
              <Button label='Save' type='submit' className='p-button-info mat-assessment-create__card_btn' />
            </div>
          </form>        
        </Card>
      </div>
    </>)
}

export default assessmentV3Connector(EditAssessment);
import { IPublicClientApplication } from '@azure/msal-browser';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { connect, ConnectedProps } from 'react-redux';
import { CreateAssessmentPayload, GetAssessmentsResponse } from '../../model';
import { addAssessmentLink, getAssessmentLink, patchAssessmentLink} from '../../services/api-entrypoints';
import { getAssessments as getAssessmentsApi } from '../../services/api-service';
import { buildApi } from '../../services/matApiService';
import { AppDispatch, RootState } from '../create-store';
import { Assessment } from '../mat-state';

export type AssessmentV3State = {
  byId: {
    [id: string]: Assessment;
  };
  allIds: string[];
  loading: boolean;
}

const initialState: AssessmentV3State = {
  byId: {},
  allIds: [],
  loading: false,
}

type AddAssessmentArgs = {
  data: CreateAssessmentPayload;
  instance: IPublicClientApplication;
}

type EditAssessmentArgs = {
  assessmentId: string;
  data: CreateAssessmentPayload;
  instance: IPublicClientApplication;
}

type GetAssessmentArgs = {
  assessmentId: string;
  instance: IPublicClientApplication;
}

type GetAssessmentsArgs = {
  instance: IPublicClientApplication;
}

export const getAssessments = createAsyncThunk(
  // TODO: Update this when API endpoint is updated
  'assessment/v3/getAssessments',
  async (args: GetAssessmentsArgs, thunkAPI) => {
    if(args.instance) {
      const response = await getAssessmentsApi(args.instance)

      if (response.ok) {
        return thunkAPI.fulfillWithValue(await response.json() as GetAssessmentsResponse);
      } else {
        return thunkAPI.rejectWithValue(new Error(`Error retrieving assessments: ${response.statusText}`));
      }
    }
    return thunkAPI.rejectWithValue(new Error('Error retrieving assessments.'));
  }
)

export const getAssessment = createAsyncThunk(
  'assessment/v3/getAssessment',
  async (args: GetAssessmentArgs, thunkAPI) => {
    if (args.instance) {
      const { read: getLink } = buildApi(args.instance);

      const response = await getLink(getAssessmentLink(args.assessmentId));

      if (response.ok) {
        const result = await response.json().then(res => res as Assessment);
        return thunkAPI.fulfillWithValue(result);
      } else {
        return thunkAPI.rejectWithValue(new Error(`Error getting assessment: ${args.assessmentId}`));
      }
    }
    return thunkAPI.rejectWithValue(new Error('Error retrieving assessment.'));
  }
)

export const addAssessment = createAsyncThunk(
  // TODO: Update this when API endpoint is updated
  'assessment/v3/addAssessment',
  async (args: AddAssessmentArgs, thunkAPI) => {

    if (args.instance) {
      const {create: postLink} = buildApi(args.instance)

      const addAssessmentResponse = await postLink(addAssessmentLink, args.data);

      if (addAssessmentResponse.ok) {
        return thunkAPI.fulfillWithValue(await addAssessmentResponse.json().then(res => res) as Assessment);
      } else {
        return thunkAPI.rejectWithValue(new Error(`Error saving new assessment: ${addAssessmentResponse.statusText}`));
      }
    }
    return thunkAPI.rejectWithValue(new Error('Error saving new assessment.'));
  })

export const editAssessment = createAsyncThunk(
  // TODO: Update this when API endpoint is updated
  'assessment/v3/editAssessment',
  async (args: EditAssessmentArgs, thunkAPI) => {

    if (args.instance) {
      const {update: patchLink} = buildApi(args.instance)

      const editAssessmentResponse = await patchLink(patchAssessmentLink(args.assessmentId), args.data);

      if (editAssessmentResponse.ok) {
        return thunkAPI.fulfillWithValue(await editAssessmentResponse.json().then(res => res) as Assessment);
      } else {
        return thunkAPI.rejectWithValue(new Error(`Error saving new assessment: ${editAssessmentResponse.statusText}`));
      }
    }
    return thunkAPI.rejectWithValue(new Error('Error saving new assessment.'));
  })

export const assessmentV3Slice = createSlice({
  name: 'assessmentv3',
  initialState,
  reducers: { },
  extraReducers(builder) {
    builder.addCase(addAssessment.fulfilled, (state, action) => {
      console.log(state, action)
      return {
        byId: { ...state.byId, [action.payload.id]: action.payload },
        allIds: [...state.allIds, action.payload.id],
        loading: false
      }
    });
    builder.addCase(addAssessment.rejected, (state, action) => {
      console.log(state, action)
      state.loading = false;
    });
    builder.addCase(getAssessments.fulfilled, (state, action) => {
      const byId: {[id: string]: Assessment} = {};
      const allIds = [];
      for (const assessment of action.payload.items) {
        byId[assessment.id] = assessment;
        allIds.push(assessment.id);
      }
      return { byId, allIds, loading: false };
    });
    builder.addCase(getAssessments.pending, (state, action) => {
      console.log(state, action)
      state.loading = true;
    });
    builder.addCase(getAssessments.rejected, (state, action) => {
      console.log(state, action)
      state.loading = false;
    });
    builder.addCase(getAssessment.fulfilled, (state, action) => {
      console.log(state, action);
      return {
        byId: { ...state.byId, [action.payload.id]: action.payload },
        allIds: [...new Set([...state.allIds, action.payload.id])],
        loading: false
      }
    });
    builder.addCase(getAssessment.pending, (state, action) => {
      console.log(state, action);
      state.loading = true;
    });
    builder.addCase(getAssessment.rejected, (state, action) => {
      console.log(state, action);
      state.loading = false;
    })
    builder.addCase(editAssessment.fulfilled, (state, action) => {
      console.log(state, action)
      return {
        byId: { ...state.byId, [action.payload.id]: action.payload },
        allIds: [...state.allIds],
        loading: false
      }
    });
    builder.addCase(editAssessment.rejected, (state, action) => {
      console.log(state, action)
      state.loading = false;
    });
    builder.addCase(editAssessment.pending, (state, action) => {
      console.log(state, action)
      state.loading = true;
    });
  },
});

const mapState = (state: RootState) => state.assessment;

const mapDispatch = (dispatch: AppDispatch) => ({
  getAssessments: (args: GetAssessmentsArgs) => dispatch(getAssessments(args)),
  addAssessment: (args: AddAssessmentArgs) => dispatch(addAssessment(args)),
  getAssessment: (args: GetAssessmentArgs) => dispatch(getAssessment(args)),
  editAssessment: (args: EditAssessmentArgs) => dispatch(editAssessment(args))
})

export const assessmentV3Connector = connect(mapState, mapDispatch);
export type WithAssessmentV3 = ConnectedProps<typeof assessmentV3Connector>;
import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { NavigateFunction, useNavigate } from 'react-router';
import { Assessment } from '../redux/mat-state';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';


type AssessmentCardProp = {
  assessment: Assessment;
}

type Image = {
  src: string;
  alt: string;
};

const imgCalendar: Image = {
  src: 'icons/calendar.svg',
  alt: 'section locked',
};
const imgLink: Image = {
  src: 'icons/link-thin.svg',
  alt: 'assessment link',
};
const imgChevronRight: Image = {
  src: 'icons/chevron-right.svg',
  alt: 'continue assessment',
};

function continueOnClick(id: string, navigate: NavigateFunction) {
  const url = `/assessment/prompt_response/${id}`;
  navigate(url);
}

function eventOnClick(id: string, navigate: NavigateFunction) {
  const url = `/assessment/waiting_room/${id}`; //TODO: Change to event url
  navigate(url);
}

function editOnClick(id: string, navigate: NavigateFunction) {
  const url = `/assessment/edit_assessment/${id}`;
  navigate(url);
}

const AssessmentCard: React.FC<AssessmentCardProp> = (props) => {
  const {assessment} = props;
  const navigate = useNavigate();

  return (
    <div
      style={{
        paddingBottom: '1px',
        float: 'left',
        width: '380px',
        padding: '5px',
      }}
    >
      <div style={{position: 'relative'}}>
        <span style={{ position: 'absolute',top: '0px',right: '8px'}}>
          <IconButton className="fac__btn" onClick={() => editOnClick(assessment.id, navigate)}>
            <SettingsIcon />
          </IconButton>
        </span>
      </div>
      <Card
        title={assessment.name || 'Assessment'}
        className='mat-ip-fac__card'
      >
        <span className='mat-ip-fac__info-key'>Client: </span>
        <span className='mat-ip-fac__info-value'>
          {assessment.client}
        </span>
        <br/>
        <span className='mat-ip-fac__info-key'>Sponsor: </span>
        <span className='mat-ip-fac__info-value'>
          {assessment.sponsor}
        </span>
        <br/>
        <div style={{paddingTop: '10px'}}>
          <img
            src={imgCalendar.src}
            alt={imgCalendar.alt}
            style={{paddingLeft: '1rem', verticalAlign: 'top'}}
          />
          <span className='mat-ip-fac__info-date'>
                      &nbsp;
            {format(
              new Date(assessment.startDate),
              'MMMM dd, yyyy h:mma'
            )}
          </span>
        </div>
        <div style={{paddingTop: '20px'}}>
          <Button
            className='mat-ip-fac__btn-link'
            onClick={() => eventOnClick(assessment.id, navigate)}
          >
            Event Link&nbsp;
            <img
              src={imgLink.src}
              alt={imgLink.alt}
              style={{height: '24px'}}
            />
          </Button>
          <Button
            className='mat-ip-fac__btn-cont'
            onClick={() => continueOnClick(assessment.id, navigate)}
          >
            Continue&nbsp;
            <img
              src={imgChevronRight.src}
              alt={imgChevronRight.alt}
              style={{height: '24px', paddingTop: '4px'}}
            />
          </Button>
        </div>
      </Card>
    </div>
  )
}

export default AssessmentCard;


import * as yup from 'yup';

export const generalValidation = yup.object().shape({
  name: yup.string().required('Required').matches(/^[\w\s]+$/, { message: 'Name must be alphanumeric', excludeEmptyString: true }),
  description: yup.string().required('Required').matches(/^[\w\s]+$/, { message: 'Name must be alphanumeric', excludeEmptyString: true }),
  client: yup.string().matches(/^[\w\s]+$/, { message: 'client must be alphanumeric', excludeEmptyString: true }),
  sponsor: yup.string().matches(/^[\w\s]+$/, { message: 'sponsor must be alphanumeric', excludeEmptyString: true }),
  startDate: yup.date().required('Required').typeError('Invalid date format'),
  endDate: yup.date().required().typeError('Invalid date format').min(yup.ref('startDate'), 'End date must be after start date'),
  finalizationDate: yup.date().required().typeError('Invalid date format')
});

export default generalValidation;